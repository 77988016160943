<template>
  <div class="bg-white h-full p-4">
    <div class="flex mb-6">
      <ArrowLeft class="mr-3 w-8 h-8 cursor-pointer" @click.native="onGoBack" />
      <h1
        class="text-black text-2xl font-bold text-center md:text-left mb-0"
      >{{ type === 'add' ? 'Thêm mới chủ đề': 'Chỉnh sửa chủ đề' }}</h1>
    </div>
    <el-input placeholder="Nhập tiêu đề bài viết" v-model="params.title"></el-input>
    <el-tabs type="card" class="mt-3">
      <el-tab-pane label="Soạn thảo">
        <EditorInput
          placeholder="Mô tả bài viết"
          :disabled="false"
          class_style="editor-input"
          id="topic_description"
          :prop_text="params.description"
          @onChangeContent="(content) => params.description = content"
        />
      </el-tab-pane>
      <el-tab-pane label="Xem trước" v-html="params.description" class="preview"></el-tab-pane>
    </el-tabs>
    <div class="add-media mt-3 flex items-center justify-between">
      <span class="font-semibold">Thêm vào bài viết của bạn</span>
      <div class="flex items-center gap-2">
        <img src="/images/icon-import-video.svg" alt class="w-6 h-6 cursor-pointer" />
        <img src="/images/icon-import-image.svg" alt class="w-6 h-6 cursor-pointer" />
      </div>
    </div>
    <div class="mt-3">
      <p class="font-semibold mb-1">Thêm nhãn cho bài viết</p>
      <el-select
        v-model="params.tags"
        value-key="id"
        multiple
        filterable
        allow-create
        push-tag
        default-first-option
        placeholder="Chọn nhãn"
        @change="handleChooseTag"
      >
        <el-option v-for="item in listTags" :key="item.id" :label="item.title" :value="item"></el-option>
      </el-select>
    </div>
    <div class="flex items-center gap-2 mt-3">
      <el-switch v-model="params.is_incognito"></el-switch>
      <span>Đăng ẩn danh</span>
    </div>
    <el-button
      type="primary"
      class="mt-3 w-full"
      @click="createTopic"
    >{{ type === 'add' ? 'Tạo chủ đề' : 'Cập nhật' }}</el-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { ArrowLeft } from '@/components/Common/Icons'
import EditorInput from '../../../components/EditorInput.vue'

export default {
  name: 'AddTopic',
  components: { EditorInput, ArrowLeft },
  props: {
    //
  },
  data () {
    return {
      params: {
        title: '',
        circle_id: +this.$route.params?.id,
        description: '',
        is_incognito: true,
        media: [],
        slug: '',
        tags: []
      },
      listTags: [],
      type: 'add',
      topicDetail: {},
      valueSelect: ''
    }
  },
  async mounted () {
    this.type = this.$route.params?.type
    if (this.type === 'edit') {
      await this.getTopicByID(this.$route.params.topic_id)
      this.params = {
        title: this.topicDetail?.title,
        circle_id: +this.$route.params?.id,
        description: this.topicDetail?.description,
        is_incognito: this.topicDetail?.is_incognito === 2,
        media: this.topicDetail?.circle_topic_media,
        slug: this.topicDetail?.slug,
        tags: this.topicDetail?.circle_topic_tags?.map(elm => elm.circle_tag)
      }
    }
    this.getListTags()
  },
  methods: {
    async getListTags () {
      try {
        const param = {
          sort_by: 'created_at',
          order: 'desc',
          page_num: 1,
          page_size: 1000,
          circle_id: +this.$route.params?.id
        }
        const response = await Vue.prototype.$rf
          .getRequest('QAndARequest')
          .fetchListTag(param)
        if (response.status === 200) {
          this.listTags = response.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getTopicByID (topic_id) {
      try {
        const response = await Vue.prototype.$rf
          .getRequest('QAndARequest')
          .fetchTopicByID(topic_id)
        if (response.status === 200) {
          this.topicDetail = response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async createTopic () {
      try {
        const param = {
          ...this.params,
          is_incognito: this.params.is_incognito === true ? 2 : 1,
          tags: this.params.tags?.map(elm => elm.id)
        }
        if (this.type === 'add') {
          console.log('add')
          const response = await Vue.prototype.$rf
            .getRequest('QAndARequest')
            .createTopic(param)
          if (response.status === 200) {
            this.$message.success('Tạo chủ đề thành công!')
            this.$router.push({ name: 'CircleQAndA' })
          }
        } else if (this.type === 'edit') {
          console.log('edit')
          const response = await Vue.prototype.$rf
            .getRequest('QAndARequest')
            .updateTopic(this.$route.params?.topic_id, param)
          if (response.status === 200) {
            this.$message.success('Chỉnh sửa chủ đề thành công!')
            this.$router.push({ name: 'CircleQAndA' })
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleChooseTag (value) {
      this.valueSelect =
        value[Object.keys(value)[Object.keys(value).length - 1]]
    },
    onGoBack () {
      return this.$router.go(-1)
    }
  },
  watch: {
    valueSelect (value) {
      if (typeof value === 'string') {
        Vue.prototype.$rf
          .getRequest('QAndARequest')
          .createTag({ circle_id: +this.$route.params?.id, title: value })
          .then(async res => {
            if (res.status === 200) {
              // this.$message.success('Tạo nhãn thành công!')
              this.params.tags.pop()
              await this.getListTags()
              this.params.tags = [
                ...this.params.tags,
                this.listTags.find(elm => elm.id === res.data.id)
              ]
            }
          })
      } else {
        //
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input__inner {
    background: white !important;
    border: 1px solid #dee2e6 !important;
    transition: all 0.3s ease;
    font-weight: 400 !important;
    &:focus {
      border-color: #a7abb1 !important;
    }
  }
  .el-select {
    width: 100%;
  }
  .editor-input {
    min-height: 200px;
    max-height: 552px;
    .ql-container {
      min-height: 152px;
      max-height: 500px;
      overflow: auto;
    }
  }
  .preview {
    padding: 12px;
    border: 1px solid #ccc;
    min-height: 200px;
  }
}
.back {
  color: #20409b;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.add-media {
  padding: 12px;
  border: 1px solid #ccc;
}

.w-5 {
  width: 20px !important;
}

.h-5 {
  height: 20px !important;
}
</style>
